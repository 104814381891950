import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const routes = [
    {
        path: '/signin/',
        name: 'sign-in',
        component: () => import('../views/SigninView.vue'),
        alias: '/',
        meta: {
            requiresAuth: false,
            allow: ['all']
        }
    },
    {
        path: '/join/',
        name: 'join',
        component: () => import('../views/JoinView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['customer support']
        }
    },
    {
        path: '/dashboard/',
        name: 'dashboard',
        component: () => import('../views/DashboardView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['all']
        }
    },
    {
        path: '/withdrawals/',
        name: 'withdrawals',
        component: () => import('../views/WithdrawalsView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['customer support']
        }
    },
    {
        path: '/withdrawal/id/:withdrawalId',
        name: 'withdrawal-details',
        component: () => import('../views/WithdrawalDetailsView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['customer support']
        }
    },
    {
        path: '/users/',
        name: 'users',
        component: () => import('../views/UsersView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['customer support']
        }
    },
    {
        path: '/user/id/:userId',
        name: 'user-details',
        component: () => import('../views/UserDetailsView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['customer support']
        }
    },
    {
        path: '/shops/',
        name: 'shops',
        component: () => import('../views/ShopsView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['customer support']
        }
    },
    {
        path: '/shop/id/:shopId',
        name: 'shop-details',
        component: () => import('../views/ShopDetailsView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['customer support']
        }
    },
    {
        path: '/items/',
        name: 'items',
        component: () => import('../views/ItemsView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['customer support']
        }
    },
    {
        path: '/item/id/:itemId',
        name: 'item-details',
        component: () => import('../views/ItemDetailsView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['customer support']
        }
    },
    {
        path: '/orders/',
        name: 'orders',
        component: () => import('../views/OrdersView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['order fulfilment clerk']
        }
    },
    {
        path: '/order/uid/:orderUid',
        name: 'order-details',
        component: () => import('../views/OrderDetailsView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['order fulfilment clerk']
        }
    },
    {
        path: '/returns/',
        name: 'returns',
        component: () => import('../views/ReturnsView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['order fulfilment clerk']
        }
    },
    {
        path: '/payments/',
        name: 'payments',
        component: () => import('../views/PaymentsView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['all']
        }
    },
    {
        path: '/manage-voucher/',
        name: 'manage-voucher',
        component: () => import('../views/ManageVoucherView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['customer support']
        }
    },
    {
        path: '/logs/',
        name: 'logs',
        component: () => import('../views/AdminLogsView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['customer support']
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/views/NotFoundView.vue'),
        meta: {
            requiresAuth: false,
            allow: ['all']
        }
    },
    {
        path: '/forbidden/',
        name: 'forbidden',
        component: () => import('@/views/ForbiddenView.vue'),
        meta: {
            requiresAuth: false,
            allow: ['all']
        }
    },


    // Admin account routes
    {
        path: '/accounts/profile/:action/',
        name: 'profile',
        component: () => import('../views/accounts/ProfileView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['all']
        }
    },
    {
        path: '/accounts/change-password/',
        name: 'change-password',
        component: () => import('../views/accounts/ChangePasswordView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['all']
        }
    },
    {
        path: '/signout/',
        name: 'sign-out',
        component: () => import('../views/accounts/SignoutView.vue'),
        meta: {
            requiresAuth: true,
            allow: ['all']
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition){
        if(savedPosition){
            return savedPosition
        }else{
            return {left: 0, top: 0}
        }
    }
})

router.beforeEach((to, from, next) => {
    axios.get(`/admin/auth/status/aeb3c5055b3dc936802a1e22838a6602`)
    .then((response) => {
        
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

        if(requiresAuth && !response.data.allow){

            next({ name: 'sign-in', query: { 'next': encodeURIComponent(to.fullPath) } })

        }else{

            const isAuthorized = (response.data.allow) ? to.meta.allow.includes(response.data.admin.admin_department) : true

            if(!isAuthorized && to.meta.allow[0] != 'all'){

                next({ name: 'forbidden' })

            }else{

                next()

            }
        }

    })
})

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start()
    }
    next()
})
  
router.afterEach(() => {
    NProgress.done()
})


export default router
